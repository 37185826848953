import React, { useEffect, useState, forwardRef } from "react";
import "./app.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Results from "./results";

function App() {
	const [kenoResults, setKenoResults] = useState(null);
	const [resultsAvailable, setResultsAvailable] = useState(false);

	const [timeOfDay, setTimeOfDay] = useState(0);
	const today = new Date();
	// today.setDate(today.getDate() - 1);
	const [selectedDate, setSelectedDate] = useState(today);

	const [openDatePicker, setOpenDatePicker] = useState(false);

	const handleDatePicker = date => {
		setSelectedDate(date);
		setOpenDatePicker(!openDatePicker);
	};

	const setResultsIfExist = data => {
		if (data) {
			if (!resultsAvailable) {
				if (data[2]) {
					setKenoResults(data[2]);
					setTimeOfDay(2);
				} else if (data[1]) {
					setKenoResults(data[1]);
					setTimeOfDay(1);
				} else if (data[0]) {
					setKenoResults(data[0]);
					setTimeOfDay(0);
				}
			} else {
				setKenoResults(data[timeOfDay]);
			}
		} else {
			if (!resultsAvailable) {
				setKenoResults({ day: selectedDate.getDate() });
			} else {
				setKenoResults({});
			}
		}
	};

	// Get all results from api to kenoResults state
	const getResults = async () => {
		fetch(
			"https://keno.lottoarvonta.com/api/?result&year=" +
				// "http://localhost/kenowidget/api/?result&year=" +
				selectedDate.getFullYear() +
				"&month=" +
				(selectedDate.getMonth() + 1) +
				"&day=" +
				selectedDate.getDate()
		)
			.then(res => res.json())
			.then(res => {
				if (res && res[0]) {
					setResultsIfExist(res);
				} else {
					setResultsIfExist(null);
				}
			})
			.catch(woops => console.log(woops));
	};

	useEffect(() => {
		// console.log(kenoResults);
		if (kenoResults && kenoResults.time) {
			setResultsAvailable(true);
		} else if (kenoResults && kenoResults.day) {
			let previousDay = new Date(
				selectedDate.setDate(selectedDate.getDate() - 1)
			);
			setSelectedDate(previousDay);
		}
		// eslint-disable-next-line
	}, [kenoResults]);

	useEffect(() => {
		getResults();
		// eslint-disable-next-line
	}, [selectedDate, timeOfDay]);

	const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
		<React.Fragment>
			<div onClick={onClick} ref={ref} className="selectRound">
				<h5>Valittu päivämäärä:</h5>
				<input type="text" defaultValue={value}></input>
			</div>
			<button onClick={onClick} ref={ref} className="button kenoSearch">
				Vaihda päivä
			</button>
		</React.Fragment>
	));

	return (
		<div id="app">
			<div id="kenoWidget">
				{resultsAvailable ? (
					<div className="widgetBody">
						<div className="selectTimes">
							<DatePicker
								dateFormat="dd.MM.yyyy"
								selected={selectedDate}
								onChange={handleDatePicker}
								maxDate={today}
								customInput={<ExampleCustomInput />}
							/>
						</div>
						<div className="kenoResults">
							<h3>
								Keno Tulokset, {selectedDate.getDate()}.
								{selectedDate.getMonth() + 1}.
								{selectedDate.getFullYear()}
							</h3>
							<div className="kenoTabs">
								<button
									className={
										timeOfDay === 0
											? "button buttonWhite active"
											: "button buttonWhite"
									}
									onClick={() => setTimeOfDay(0)}
								>
									Päivä
								</button>
								<button
									className={
										timeOfDay === 1
											? "button buttonWhite active"
											: "button buttonWhite"
									}
									onClick={() => setTimeOfDay(1)}
								>
									Ilta
								</button>
								<button
									className={
										timeOfDay === 2
											? "button buttonWhite active"
											: "button buttonWhite"
									}
									onClick={() => setTimeOfDay(2)}
								>
									Myöhäisilta
								</button>
							</div>

							<Results
								kenoResults={kenoResults}
								timeOfDay={timeOfDay}
							/>
						</div>
					</div>
				) : (
					""
				)}
			</div>
		</div>
	);
}

export default App;
