import React, { useState, useRef, useEffect, Fragment } from "react";

function Home(props) {
	const [winnings, setWinnings] = useState(false);
	const [kingMultiplier, setKingMultiplier] = useState(false);
	const [winningsLevel, setWinningsLevel] = useState(10);

	const [balls, setBalls] = useState("");
	const [kingBall, setKingBall] = useState("");

	const toggleWinnings = () => {
		setWinnings(!winnings);
	};

	// Scroll to "Voitonjako" when button pushed
	const winningTitle = useRef(null);
	useEffect(() => {
		if (winnings && winningTitle && winningTitle.current) {
			let scrollTo = winningTitle.current.offsetTop;
			window.scrollTo(0, scrollTo);
		}
	}, [winnings]);

	// Check that data is available
	useEffect(() => {
		// console.log(props.kenoResults);
		if (
			props.kenoResults &&
			props.kenoResults.data &&
			props.kenoResults.data.results &&
			props.kenoResults.data.results[0] &&
			props.kenoResults.data.results[0].primary
		) {
			if (props.kenoResults.data.results[0].secondary) {
				setKingBall(props.kenoResults.data.results[0].secondary);
			}
			// Print balls
			const allBalls = [];
			for (
				let i = 0;
				i < props.kenoResults.data.results[0].primary.length;
				i++
			) {
				allBalls.push(
					<div key={i} className="lottoBall">
						{props.kenoResults.data.results[0].primary[i]}
					</div>
				);
			}
			setBalls(allBalls);
		} else {
			setBalls("");
			setKingBall("");
		}
	}, [props.kenoResults, props.timeOfDay]);

	// Print levels
	let levelTabs = [];
	for (let i = 11; i >= 2; i--) {
		if (winningsLevel === i) {
			levelTabs.push(
				<span onClick={() => setWinningsLevel(i)} className="active">
					{i}
				</span>
			);
		} else {
			levelTabs.push(
				<span onClick={() => setWinningsLevel(i)}>{i}</span>
			);
		}
	}

	// Print winnings
	let winningsCell = [];
	if (
		props.kenoResults &&
		props.kenoResults.data &&
		props.kenoResults.data.wins &&
		winningsLevel
	) {
		if (
			props.kenoResults.data.wins.normal &&
			props.kenoResults.data.wins.normal[winningsLevel] &&
			!kingMultiplier
		) {
			props.kenoResults.data.wins.normal[winningsLevel].forEach(
				(item, i) => {
					winningsCell.push(
						<Fragment>
							<td>{item.matches}</td>
							<td>{item.multiplier}</td>
							<td>{item.count}</td>
						</Fragment>
					);
				}
			);
		}
		if (
			props.kenoResults.data.wins.kunkku &&
			props.kenoResults.data.wins.kunkku[winningsLevel] &&
			kingMultiplier
		) {
			props.kenoResults.data.wins.kunkku[winningsLevel].forEach(
				(item, i) => {
					winningsCell.push(
						<Fragment>
							<td>{item.matches}</td>
							<td>{item.multiplier}</td>
							<td>{item.count}</td>
						</Fragment>
					);
				}
			);
		}
	}

	return (
		<React.Fragment>
			{balls ? (
				<React.Fragment>
					<div className="resultRow">
						<div className="resultColumn allBalls">{balls}</div>
						<div className="resultColumn kingNumber">
							<p>KUNKKU-NUMERO</p>
							<div className="lottoBall red">{kingBall}</div>
						</div>
					</div>
					<div className="winTables">
						<h3 ref={winningTitle}>Voitonjako</h3>
						{winnings ? (
							<React.Fragment>
								<div className="levelButtons">
									<button
										className={
											!kingMultiplier
												? "button buttonWhite active"
												: "button buttonWhite"
										}
										onClick={() => setKingMultiplier(false)}
									>
										Normaali
									</button>
									<button
										className={
											kingMultiplier
												? "button buttonWhite active"
												: "button buttonWhite"
										}
										onClick={() => setKingMultiplier(true)}
									>
										KunkkuKerroin
									</button>
								</div>
								<div className="levelTabs">
									<div className="levelText">Taso:</div>
									<div className="levels">
										<ul>
											{levelTabs.map((value, index) => {
												return (
													<li key={index}>{value}</li>
												);
											})}
										</ul>
									</div>
								</div>
								<table>
									<thead>
										<tr>
											<th>Tulos</th>
											<th>Kerroin</th>
											<th>Kpl</th>
										</tr>
									</thead>
									<tbody>
										{winningsCell.map((value, index) => {
											return <tr key={index}>{value}</tr>;
										})}
									</tbody>
								</table>
							</React.Fragment>
						) : (
							""
						)}
					</div>
					<button
						className="button winningsButton"
						onClick={toggleWinnings}
					>
						{winnings ? "Piilota" : "Näytä"} voitonjako
					</button>
				</React.Fragment>
			) : (
				"Ei tuloksia"
			)}
		</React.Fragment>
	);
}

export default Home;
